import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AniLink from "../../components/AniLink"

import { portfolioSchema1 as services } from "../../assets/js/helpers"

import f2cFurnitureOpportunity from '../../assets/images/portfolio/f2c-furniture/f2c-furniture-opportunity.jpg';
import f2cFurniturePortfolio from '../../assets/images/portfolio/f2c-furniture/portfolio-f2c-furniture.png';
import f2cFurnitureHow from '../../assets/images/portfolio/f2c-furniture/f2c-how.jpg';
import f2cFurnitureImpact from '../../assets/images/portfolio/f2c-furniture/f2c-impact.png';

function F2CFurniturePage() {
  return (
    <Layout>
      <SEO
        title="Portfolio - Sir Salon"
        description="An Auto Reply System That Integrated To Social Media Chat."
      />
      <section className="section-top-200">
        <div className="container">
          <div className="text-center">
            <div className="small-caption color-cerulean-blue">Reseller Commerce</div>
            <div className="title color-raven-black">F2C Furniture</div>
            <div className="subtitle color-raven-black">
              We work with F2C furniture to build a futuristic furniture reseller platform with the notion of selling goods directly from the factory.
            </div>

            <div className="landing-service section-50">
              {services.map((value, key) => {
                return (
                  <AniLink
                    to={value.link}
                    key={key}
                    className="landing-service-item"
                  >
                    <img src={value.image} alt={value.id} />
                    <div className="landing-service-item-description">
                      {value.title}
                    </div>
                  </AniLink>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="section-top-200">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-4 mb-md-0">
              <img
                className="portfolio-image"
                src={f2cFurnitureOpportunity}
              />
            </div>
            <div className="col-md-7 text-center text-md-left pl-md-5">
              <div className="subtitle color-cerulean-blue">OPPORTUNITY</div>
              <div className="description">
                <p>
                  F2C Furniture, which is a furniture factory that has decades of experience, from processing raw materials to finished materials. Of the business side too has worked on national and international markets, ranging from Malaysia, China, Taiwan to the United States.
                </p>
                <p>
                  Seeing the available infrastructure and the booming online economy, making F2C Furniture wants to provide opportunities for everyone to be able to do business online, but with a smooth and systematic job.
                </p>
              </div>
            </div>
          </div>

          <div className="row section-top-150">
            <div className="col-md-7 pr-5 mb-4 mb-md-0">
              <div className="subtitle color-cerulean-blue">SOLUTION</div>
              <div className="description">
                <p className="heading color-raven-black">
                  A reseller platform for everyone.
                </p>
                <p>
                  Selling with many business partners is no longer a dilemma. Springkraf and F2C Furniture come with an integrated reseller platform that contains online catalogues, shipping costs, payments, and commission calculations.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="portfolio-image no-shadow"
                src={f2cFurniturePortfolio}
              />
            </div>
          </div>

          <div className="row section-top-150">
            <div className="col-md-5 mb-4 mb-md-0">
              <img
                className="portfolio-image"
                src={f2cFurnitureHow}
              />
            </div>
            <div className="col-md-7 text-center text-md-left pl-md-5">
              <div className="subtitle color-cerulean-blue">HOW</div>
              <div className="description">
                <p>
                  Starting from a rough idea to implementation, that's what can be said here. Working closely with the highly enthusiast F2C Furniture's team, we have never been discouraged in building F2C Furniture to be the most excellent reseller platform at Indonesia.
                </p>
              </div>
            </div>
          </div>

          <div className="row section-top-150 section-bottom-200">
            <div className="col-md-7 pr-5 mb-4 mb-md-0">
              <div className="subtitle color-cerulean-blue">IMPACT</div>
              <div className="description">
                <p className="heading color-raven-black">
                  Making F2C furniture can embrace and build more successful online entrepreneurs.
                </p>
                <p>
                  Starting from the idea, the right software development and supporting resources. Making F2C Furniture able to appear in less than two months and helping thousands of active resellers afterwards.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="portfolio-image"
                src={f2cFurnitureImpact}
              // fluid={images.impact.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-top-150 section-bottom-200">
        <div className="container">
          <div className="text-center">
            <div className="small-caption color-cerulean-blue">Approach</div>
            <div className="title color-raven-black">How it works</div>
          </div>
          <div className="description margin-bottom-50">
            <p>
              Step by step to booking any services :
            </p>
            <ul>
              <li>Find your favourite outlet</li>
              <li>Choose any service that you want and click next</li>
              <li>
                Choose your stylist, if you want to random, then choose random
                stylist
              </li>
              <li>Select available date & time and click next</li>
              <li>Click Confirm Booking</li>
            </ul>
          </div>
          <Img
            className="portfolio-image no-shadow"
            fluid={images.howItWorks.childImageSharp.fluid}
            width="100%"
          />
        </div>
      </section> */}

      <section id="contact">
        <div className="contact">
          <div className="p-relative section-100 container text-center text-md-left">
            <div className="heading color-pine-green mb-2">
              Partner with us!
            </div>
            <div className="mb-5 color-spring-green">
              Start scaling up your business and be one of our successful
              partner!
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
              target="_blank"
            >
              <div className="d-inline button button-primary">Chat with us</div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default F2CFurniturePage
